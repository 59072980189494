'use strict';

class LoginController {
    //start-non-standard
    user = {};
    errors = {};
    submitted = false;
    //end-non-standard

    constructor(Auth, $state, $rootScope) {
        this.Auth = Auth;
        this.$state = $state;
        this.$rootScope = $rootScope;
    }

    login(form) {
        this.submitted = true;

        if (form.$valid) {
            this.Auth.login({
                    email: this.user.email,
                    password: this.user.password
                })
                .then(() => {

                    if (!angular.isUndefined(this.$rootScope)) {

                        // Si hay intencion de voto, la recuperamos
                        if (!angular.isUndefined(this.$rootScope.votePreLogin)) {
                            // Redirigimos segun origen del voto
                            this.$rootScope.votePreLogin.fromState === "product" ?
                                this.$state.go('main.vote.category.product', { slugCategory: this.$rootScope.votePreLogin.category, slugProduct: this.$rootScope.votePreLogin.product.slug }, {
                                    location: true,
                                    notify: true
                                }) :
                                this.$state.go('main.vote.category', { slugCategory: this.$rootScope.votePreLogin.category }, {
                                    location: true,
                                    notify: true
                                })

                        } else {
                            // Logged in, redirect to votacion
                            this.$state.go('main.vote');
                        }
                    }
                })
                .catch(err => {
                    this.errors.other = err.message;
                    this.votePreLogin = null
                });
        }
    }
}

angular.module('mvpcarrefourApp')
    .controller('LoginController', LoginController);
